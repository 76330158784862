@import 'assets/styles/main';

body {
  margin: 0;
  font-family: 'Prompt', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --mui-palette-primary: #fa230d;
  --mui-palette-secondary: #072978;
}
