@import '../../../assets/styles/main';

.nameBusiness {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}

.nameBusiness:hover {
  overflow: visible !important;
  white-space: unset !important;
}

ul.recharts-default-legend {
  text-align: left !important;
}

.recharts-legend-wrapper {
  bottom: 10px !important;
  left: 60px !important;
  font-size: smaller;
}
