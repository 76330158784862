@import '../../assets/styles/main';

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: -0.2rem;
  height: 580px;
  justify-content: center;
}

.textHeader {
  color: $primary-red;
  font-size: 2rem;
  font-weight: 700 !important;
  padding: 10px 0px;
}
// color: rgba(0, 0, 0, 0.87);
.textSubHeader {
  font-weight: 700 !important;
  letter-spacing: 0.01rem;
}
.btnOK {
  width: 400px;
  margin-top: 30px;
  margin-top: 44px !important;
}
