.containerLineChart {
  height: 390px;
  min-width: 1224px;
  max-width: 1024px;
  overflow: hidden;
  overflow-x: scroll;
}

.nameLateSceen {
  text-align: center;
}
