@import '../../assets/styles/main.scss';

#backdrop {
  margin: 0px;
  z-index: 100 !important;
}

#close-icon {
  padding: 15px 15px 0 15px;
  display: flex;
  justify-content: flex-end;
}

.sidebarContainer {
  padding: 0px 30px;
}

.sidebarHeader {
  width: 300px;
  padding: 5px 30px 15px 30px;

  #sidebar-title {
    color: $primary-red;
  }
}
.btnSelectAll {
  font-weight: bold !important;
  font-size: 14px !important;
  padding: 24px 0px;
}

.btnClearAll {
  color: rgba(0, 0, 0, 0.26) !important;
  font-weight: bold !important;
  font-size: 14px !important;
  padding: 24px 0px;
}

.sidebarChoiceContainer {
  height: 570px;
  overflow-x: scroll;
  padding-top: 15px;
}
.sidebarFooter {
  padding: 0 30px;
  margin-top: -50px;
  width: 298px;
}

#apply-button {
  width: 270px;
}

.MuiAutocomplete-popupIndicator {
  transform: rotate(0deg) !important;
  color: $primary-red !important;
}

#select-clear-buttons {
  margin-top: -8px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  border-radius: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: thin;
  border-top: none;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-ur5974-MuiInputBase-root-MuiOutlinedInput-root {
  max-height: 59.28px;
  min-height: 59.28px;
  overflow: hidden;
}
.MuiAutocomplete-paper {
  overflow: hidden;
  overflow-y: scroll;
  max-height: 460px;
  min-height: 460px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: thin;
  box-shadow: none !important;
  border-bottom: none;
  margin-top: 6px;
  padding-top: 4px;
  border-radius: 0px !important;
}

// .MuiAutocomplete-noOptions.css-6wwd73-MuiAutocomplete-noOptions {
//   display: none;
// }

.MuiAutocomplete-paper ul {
  height: 440px;
  min-height: 440px;
  max-height: 440px;
  margin-top: -8px;
}

.MuiChip-filled.MuiChip-sizeMedium {
  display: none;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  border-radius: 0;
}
.hrTop {
  border: none;
  width: 100%;
  padding: 0 6px;
  margin-top: -17px;
  z-index: 1;
  margin: 0px 2px;
}
.hrTopBtn {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: thin;
  box-shadow: none !important;
  padding: 0 14px;
  margin-top: 2px;
}
.bottomSide {
  margin-top: -4px;
}

.blockApply {
  margin-top: 40px;
}
