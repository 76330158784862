@import '../../assets/styles/main';

@font-face {
  font-family: 'PromptMedium';
  src: url('../../assets/fonts/Prompt/Prompt-MediumItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.appBar {
  display: 'flex';
  background-color: $platinum !important;
  padding-bottom: 4px;
  padding-left: 24px !important;
  z-index: 1 !important;
}

.Mui-selected {
  color: $black !important;
  background-color: transparent !important;
}

.tollBar {
  display: flex;
  flex-wrap: wrap;
}
.css-h4y409-MuiList-root {
  padding: 0px 0px !important;
}

.placeHolderTitle {
  display: flex;
  flex: 1 100%;
  color: $black;
  font-weight: 500 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionSticky.appBar.css-fnb2o9-MuiPaper-root-MuiAppBar-root {
  z-index: 1 !important;
}
.btnScreen {
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  max-width: 300px !important;
  min-width: 164px !important;
}
.btnScreenSale {
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  max-width: 300px !important;
  min-width: 133px !important;
}

.inActive {
  color: $fontGrey !important;
  padding-left: 0px !important;
}
.active {
  color: #000000 !important;
  padding-left: 0px !important;
}

.MuiListItemText-primary {
  font-size: 16px !important;
  font-weight: 700 !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  text-align: left !important;
  font-weight: 700 !important;
  margin: 0;
}

.listMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 8px 16px !important;
  padding-left: 0 !important;
}
