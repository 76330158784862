.fixColumn {
  display: flex;
  flex-direction: column;
  position: sticky !important;
  left: 0 !important;
  z-index: 10 !important;
  background: white;
  font-size: 12px;
  //margin-top: 2.2px;
}

.rowStyle {
  border: 1px solid #ccc;
  // border: 0.2px solid #ccc;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: center;
}
