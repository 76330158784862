@import '../../assets/styles/main.scss';

#dropdown {
  border: solid $gray 1px;
  border-radius: 7px;
  padding: 0px 6px 4px 14px;
  font-size: 14px;
  min-width: 124px;
  height: 42px;
  max-width: 124px;
  display: flex;
  justify-content: space-between;
}

#dropdown:hover {
  border-color: $black;
}

#dropdown-disabled {
  border: solid $gray 1px;
  border-radius: 7px;
  padding: 0px 6px 4px 14px;
  font-size: 14px;
  min-width: 124px;
  height: 42px;
  max-width: 124px;
  display: flex;
  justify-content: space-between;
  color: $gray;
}

#dropdown-label {
  color: $gray;
  font-size: 12px;
  padding: 0 6px 2px 6px;
}

#dropdown-label-disabled {
  color: $gray;
  font-size: 12px;
  padding: 0 6px 2px 6px;
  margin-left: -7px !important;
}

#dropdown-icon {
  color: $primary-red;
  margin-top: -4px;
}

#dropdown-icon-disabled {
  color: $dark-gray;
  margin-top: -4px;
}

legend#dropdown-label {
  margin-left: -7px !important;
}
