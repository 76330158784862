@import 'assets/styles/main';

.container {
  display: inline-flex;
  width: 100%;
  height: 100%;
}

.listMenu {
  display: flex;
}
