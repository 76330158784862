@import '../../assets/styles/main.scss';

.card-container {
  max-width: 860px;
}

.css-lcd0jh-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: $primary-red !important;
  border-radius: 4px;
}

.css-lcd0jh-MuiButtonBase-root-MuiPaginationItem-root {
  border-radius: 0 !important;
  border: solid 1px $gray !important;
  border-radius: 4px !important;
}

.snapColumn {
  overflow-wrap: break-word;
  th:first-child {
    position: sticky !important;
    left: 0 !important;
    z-index: 10 !important;
  }
  td:first-child {
    position: sticky !important;
    left: 0 !important;
    z-index: 9 !important;
    background-color: white !important;
  }
}

td {
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
  }
}
