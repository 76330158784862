@import '../../assets/styles/main';

.directionContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-bottom: 10px;
}

.labelSubmenu {
  min-width: 100px;
  min-height: 30px;
  margin: 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray;
  font-weight: bold;
  font-size: 18px;
}
.selectedDelivery {
  min-width: 100px;
  min-height: 30px;
  margin: 0px 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: $primary-red;
  font-weight: bold;
  font-size: 18px;

  border-bottom: 3px solid $primary-red;
}
