@import '../../assets/styles/main';

#group-container {
  margin: 2px 0;
}

#display-label {
  text-align: center;

  background-color: $light-grey;

  border-width: 2px;
  border-style: solid;
  border-color: $light-grey;
}

#updated-text {
  text-align: left;
}

#display-container {
  border-width: 2px;
  border-style: solid;
  border-color: $light-grey;

  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  overflow: hidden;
}

#section-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding-top: 10px;
}

#section-button-up {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding-top: 5px;
  padding-bottom: 5px;
}

#double-arrow {
  rotate: 90deg;
  margin-right: 15px;
  font-size: 30px;
}

#double-arrow-up {
  rotate: 270deg;
  margin-right: 15px;
  font-size: 30px;
}

.paletteSquare {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-top: 7px;
}

.blank {
  background-color: $white;
}
.transitTime {
  background-color: $gray;
}
.Departure {
  background-color: $yellow;
}

.empty-box {
  width: 145.33px;
  height: 47.33px;
}
