@font-face {
  font-family: 'Prompt';
  src: url('../fonts/Prompt/Prompt-Regular.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('../fonts/Prompt/Prompt-Italic.ttf') format('truetype');

  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('../fonts/Prompt/Prompt-SemiBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('../fonts/Prompt/Prompt-SemiBoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('../fonts/Prompt/Prompt-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Prompt';
  src: url('../fonts/Prompt/Prompt-MediumItalic.ttf') format('truetype');

  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Teko';
  src: url('../fonts/Teko/Teko-Regular.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
}
