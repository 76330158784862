@import '../../assets/styles/main';
.cardContent {
  border: $gray solid 1px;
}
.herderTitler {
  background-color: $light-grey;
  flex: 1;
  text-align: center;
  font-weight: 700;
}

.styletable {
  flex-direction: row !important;
  margin-top: 0px;
  max-width: 800px;
  min-width: 800px;
  overflow-x: scroll;
}
.colorSquare {
  width: 12px !important;
  height: 12px !important;
  border-radius: 2px;
  margin-top: 7px;
}
.blank {
  background-color: $white;
}
.delivery {
  background-color: $zambezi;
}
.inTransit {
  background-color: #fa230d;
}
.waitForShipping {
  background-color: $yellow;
}
.inProcess {
  background-color: $gray;
}
.reJected {
  background-color: $bright-turquoise;
}

.containerChart {
  margin-top: 0px;
  overflow: hidden;
  max-width: 1111px;
  min-width: 1111px;
  margin-bottom: 10px;
  margin-left: 10px;
  overflow: hidden;
  overflow-x: scroll;
  // z-index: -1;
}
.nationPieChart {
  position: absolute;
  left: 51px;
  z-index: 11;
  background-color: white;
  padding-right: 40px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  padding: 12px 12px 12px 20px;
}
.nextChart {
  margin-left: 327px !important;
}
.regionChart {
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.boxMain {
  margin: 0;
  margin-top: 0 !important;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
  overflow: hidden !important;
  z-index: 0 !important;
}
.lastUpdate {
  padding: 14px 0px 10px 0px;
  margin-left: 14px;
}

.containerByAreaBarChart {
  padding-left: 330px;
  margin-top: -30px;
  margin-right: 30px;
  max-width: 800px;
  padding-bottom: 200px;
}

.containerBarChart {
  position: fixed;
  right: 33px;
  height: 100%;
}

.view-detail {
  color: $gray;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.empty-box {
  width: 145.33px;
  height: 47.33px;
}
// .boxTable {
//   overflow: scroll;
//   width: calc(100% + 300px);
//   height: calc(100% + 300px);
//   padding-right: calc(100% + 300px);
// }
