@import '../../assets/styles/main.scss';

#backdrop {
  margin: 0px;
  z-index: 100 !important;
}

#close-icon {
  padding: 15px 15px 0 15px;
  display: flex;
  justify-content: flex-end;
}

#sidebar-header {
  width: 300px;
  padding: 15px 60px 15px 30px;

  #sidebar-title {
    color: $primary-red;
  }
}

#sidebar-choice-container {
  padding-top: 10px;
}

#selected-select-choice {
  display: inline-flex;
  padding: 5px 10px;
  margin: 5px;

  border-radius: 10px;
  border-color: $gray;
  border-width: 1px;
  border-style: solid;

  background-color: $primary-red;
  color: $white;

  font-size: 15px;
}

#select-choice {
  display: inline-flex;
  padding: 5px 10px;
  margin: 5px;

  border-radius: 10px;
  border-color: $gray;
  border-width: 1px;
  border-style: solid;

  background-color: $white;

  font-size: 15px;
}

#sidebar-footer {
  padding: 15px 60px 50px 50px;
  bottom: 0;
  position: fixed;
  right: 0;
}

#apply-button {
  width: 270px;
}
