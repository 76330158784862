@import '../../assets/styles/main.scss';

#chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#chart-label-color {
  display: flex;
  align-content: center;
}

#color-label {
  margin-top: auto;
  margin-bottom: auto;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

#chart-label-container {
  margin-top: 20px;
}

#chart-label-text {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 0.75fr 0.6fr;
}

#chart-label-text-name {
  margin: 0 10px;
}

#chart-label-text-value {
  margin-right: 10px;
  justify-content: flex-end;
  display: flex;
}
