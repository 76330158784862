@import '../../../assets/styles/main';
.textLastUpdate {
  font-size: 14px;
  margin-bottom: 18px;
}
.colorSquare {
  width: 20px;
  height: 20px;
  border-radius: 2px;
}
.delivery {
  background-color: $bright-turquoise;
}
.inTransit {
  background-color: $light-grey;
}
.inProcess {
  background-color: $primary-red;
}
.reJected {
  background-color: $zambezi;
}
.titleChart {
  background-color: $light-grey;
  text-align: center;
}
.lineHorizontal {
  margin: 14px 0px !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
}
.tableChart {
  margin-top: -64px;
}
.noMarginTop {
  margin-top: 0px !important;
}
