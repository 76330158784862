/*--------------------
    Colour
--------------------*/
$primary-red: var(--mui-palette-primary) !default;
$primary-blue: var(--mui-palette-secondary) !default;
$primary-linear-gradient-color: linear-gradient(to right, #e31a23, #ae000d) !default;

$black: #000000 !default;
$zambezi: #585858 !default;
$gray: #c7c7c7 !default;
$light-grey: #ececec !default;
$white: #ffffff !default;
$dark-gray: #3f3f3f !default;
$yellow: #febf00 !default;
$bright-turquoise: #12f4e1 !default;
$platinum: #dee0e5 !default;
$fontGrey: #979797 !default;

/*--------------------
      Size
  --------------------*/
$size-100px: 6.25rem !default;
$size-90px: 5.625rem !default;
$size-72px: 4.5rem !default;
$size-64px: 4rem !default;
$size-52px: 3.25rem !default;
$size-48px: 3rem !default;
$size-42px: 2.625rem !default;
$size-38px: 2.375rem !default;
$size-36px: 2.25rem !default;
$size-34px: 2.125rem !default;
$size-32px: 2rem !default;
$size-30px: 1.875rem !default;
$size-28px: 1.75rem !default;
$size-26px: 1.625rem !default;
$size-24px: 1.5rem !default;
$size-22px: 1.375rem !default;
$size-20px: 1.25rem !default;
$size-18px: 1.125rem !default;
$size-16px: 1rem !default;
$size-14px: 0.875rem !default;
$size-12px: 0.75rem !default;
$size-10px: 0.625rem !default;
$size-8px: 0.5rem !default;
$size-5px: 0.313rem !default;

/*--------------------
    Responsive Size
--------------------*/
$screen-mobile-small-min: 350px;
$screen-mobile-min: 500px;
$screen-tablet-min: 1024px;
$screen-desktop-min: 1440px;
$screen-large-desktop-min: 1920px;
